var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticStyle:{"width":"100%","margin-bottom":"30px"},attrs:{"data":_vm.sections,"border":""}},[_c('el-table-column',{attrs:{"label":"Section","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',[_vm._v(_vm._s(scope.row.name))])]}}])}),_c('el-table-column',{attrs:{"prop":"passage","label":"Passages","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!_vm.TEST_INFORMATION[scope.row.name].passages)?_c('span',[_vm._v("-")]):_c('router-link',{attrs:{"to":{
          name: 'Passages',
          query: { examId: scope.row.sat_id, sectionId: scope.row.id }
        }}},[(
            _vm.TEST_INFORMATION[scope.row.name].passages ===
              scope.row.passages.length
          )?_c('span',[_vm._v(" "+_vm._s(scope.row.passages.length)+" ")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(`${scope.row.passages.length}/${ _vm.TEST_INFORMATION[scope.row.name].passages }`)+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"passage","label":"Answer","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
          name: 'Questions',
          query: { examId: scope.row.sat_id, sectionId: scope.row.id }
        }}},[(
            _vm.TEST_INFORMATION[scope.row.name].questions ===
              scope.row.questions.length
          )?_c('span',[_vm._v(" "+_vm._s(scope.row.questions.length)+" ")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(`${scope.row.questions.length}/${ _vm.TEST_INFORMATION[scope.row.name].questions }`)+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"passage","label":"Question","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
          name: 'Questions',
          query: { examId: scope.row.sat_id, sectionId: scope.row.id }
        }}},[(
            _vm.TEST_INFORMATION[scope.row.name].questions ===
              scope.row.questions.filter(
                question => question.content || question.options.length !== 0
              ).length
          )?_c('span',[_vm._v(" "+_vm._s(scope.row.questions.length)+" ")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(`${ scope.row.questions.filter( question => question.content || question.options.length !== 0 ).length }/${_vm.TEST_INFORMATION[scope.row.name].questions}`)+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"passage","label":"Explain","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
          name: 'Questions',
          query: { examId: scope.row.sat_id, sectionId: scope.row.id }
        }}},[(
            _vm.TEST_INFORMATION[scope.row.name].questions ===
              scope.row.questions.filter(question => question.exp).length
          )?_c('span',[_vm._v(" "+_vm._s(scope.row.questions.length)+" ")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(`${ scope.row.questions.filter(question => question.exp).length }/${_vm.TEST_INFORMATION[scope.row.name].questions}`)+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.instant.formatDate(scope.row.date))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }